import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import convertBitsToMb from "../convertbits/convertBitsToMb";
import convertBitsToGB from "../convertbits/convertbitstoGb";
import convertBitsToTB from "../convertbits/convertBitsToTB";
import {
  setPlanName,
  setPrice,
} from "../store/chooseBestOffers/BestOffersSlicer";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { setReset } from "../store/downloadButtonRefernecSliceReducer";
import CustomAlert from "../CustomAlertForSubmision";



const Other = () => {
  const fileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [files, setFiles] = useState([]);
  const [switchButton, setSwitchButton] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();
  const [filePaths, setFilePath] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const profile = useSelector((state) => state.profile);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [buttonText, setButtonText] = useState("Submit");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState("");

  const backendUrl =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://backend.zmqrcode.com"
    : "http://localhost:3000";

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get("name");
    if (queryParam) {
      setServiceName(queryParam);
    }
  }, [location]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0); // Total size in bytes
    if (totalSize > 0 && totalSize <= 52428800) {
      const plan = convertBitsToMb(totalSize, profile.usagecount);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    } else if (totalSize > 52428800 && totalSize <= 96262144000) {
      const plan = convertBitsToGB(totalSize);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    } else if (totalSize > 96262144000 && totalSize > 96262144000) {
      const plan = convertBitsToTB(totalSize);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    }
  }, [files, dispatch]);

  const chooseFileHandleChange = async (event, setFieldValue) => {
    localStorage.removeItem("serviceInstanceId");
    setButtonText("Submit");
    if (profile.username !== "") {
      setLoading(true);
      const selectedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setFieldValue("files", selectedFiles);

      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("file", file);
      });

      try {
        const uploadData = await postUploadFile(
          `${backendUrl}/upload`,
          formData
        );

        const data = uploadData.data;

        if (Array.isArray(data)) {
          const newFilePaths = data.map((item) => item.url);

          setFilePath((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
        } else {
          alert("Data is not an array", data);
        }

        dispatch(setReset());
      } catch (error) {
        const errorMessage = error.message || "An error occurred";
        const statusCode =
          error.response && error.response.status
            ? error.response.status.toString()
            : "No status code";
        setShowAlert(true);
        setAlertMessage(
          `Error uploading files: ${errorMessage} (Status Code: ${statusCode})`
        );
        console.error("Error uploading files:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setIsLogin(true);
    }
  };

  const postUploadFile = async (url, formData) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      setShowAlert(true);
      setAlertMessage("Error uploading files:", error);
      throw error;
    }
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);

      // Remove corresponding file path
      setFilePath((prevFilePaths) =>
        prevFilePaths.filter((_, i) => i !== index)
      );

      // Update localStorage after the files have been updated
      if (updatedFiles.length > 0) {
        const resultFile = updatedFiles.map((file) => ({ name: file.name }));
        localStorage.setItem("files", JSON.stringify(resultFile));
      }

      return updatedFiles;
    });

    // Clear file input if it was the last file
    if (files.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmitService = async (values, { resetForm }) => {
    if (profile.username !== "") {
      const formData = {
        serviceName: serviceName,
        fileTitle: values.imageTitle,
        fileDescription: values.description,
        filePaths: filePaths,
        QrCodePassword: values.password || "",
        userId: profile.userid,
      };

      try {
        if (filePaths.length >= 1) {
          const response = await axios.post(
            `${backendUrl}/purchaseQrCodeUser`,
            formData
          );
          const purchasedServiceId = response.data.url;
          if (purchasedServiceId) {
            dispatch(setInstanceId(purchasedServiceId));
          }
          setButtonText("Submitted");
        }

        resetForm();
        setFiles([]); // Clear the files array
        setFilePath([]); // Clear the file paths
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Reset input field
        }
      } catch (error) {
        console.error("Error creating service instance:", error);
      }
    } else {
      setIsLogin(true);
    }
  };

  const BestPlans = useSelector((state) => state.Bestplans);

  useEffect(() => {
    if (BestPlans.price !== null && BestPlans.price !== 0) {
      setAlertMessage(
        `Your current Plan ${BestPlans.planName} and price:${BestPlans.price}`
      );
      setShowAlert(true);
    }
  }, [BestPlans]);

  useEffect(() => {
    if (files.length > 1) {
      const filesToStore = files.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      }));

      localStorage.setItem("audioFiles", JSON.stringify(filesToStore));
    }
  });

  useEffect(() => {
    const storedFiles = JSON.parse(localStorage.getItem("audioFiles"));
    console.log(storedFiles);

    if (storedFiles) {
      // Reconstruct File objects (optional)
      const reconstructedFiles = storedFiles.map((file) => {
        return new File([], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
      });
      console.log(reconstructedFiles);
      setFiles(reconstructedFiles);
    }
  }, []);

  return (
    <div className="max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">
        OtherFile Upload Form
      </h1>
      <Formik
        initialValues={{
          imageTitle: "",
          description: "",
          password: "",
          files: [], // Initialize as an empty array
        }}
        onSubmit={handleSubmitService}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Image Title input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="imageTitle"
              >
                Image Title
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="imageTitle"
                placeholder="NA"
              />
            </div>

            {/* Description input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description"
              >
                Description
              </label>
              <Field
                as="textarea"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="description"
                placeholder="NA"
              />
            </div>
            <div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="file"
                >
                  Choose Image(s)
                </label>
                <input
                  id="files"
                  name="files"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg" // Only allows .png, .jpeg, and .jpg files
                  multiple // Allows multiple files to be selected
                  onChange={(event) =>
                    chooseFileHandleChange(event, setFieldValue)
                  } // Handle file selection
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ref={fileInputRef}
                />

                {loading && (
                  <ColorRing
                    visible={true}
                    height="40"
                    width="30"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    colors={[
                      "#1D91AA",
                      "#1D91AA",
                      "#1D91AA",
                      "#1D91AA",
                      "#1D91AA",
                    ]} // This applies the same color to all circles
                  />
                )}
              </div>
              <div className="mt-2">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2"
                  >
                    <span className="text-gray-700">{file.name}</span>
                    {!loading && (
                      <button
                        type="button" // Ensures this button does not act as a submit button
                        onClick={() => removeFile(index)} // Calls removeFile function with the file index
                        className="text-red-500 hover:text-red-700"
                        aria-label={`Remove file at index ${index}`} // Accessibility improvement
                      >
                        <FontAwesomeIcon icon={faTimes} className="text-red" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Password input with visibility toggle */}
            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="NA"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute 
                                    md:right-[15px]
                                    top-1/2
                                    right-[-70px]
                                    text-gray-600 hover:text-gray-900"
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    size="1x"
                    color="#1D91AA"
                  />
                </button>
              </div>
            </div>

            {/* Submit button */}
            <div className="flex items-center justify-between">
              <button
                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: "#1D91AA" }}
                disabled={isSubmitting}
              >
                {buttonText}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          onClose={() => {
            setShowAlert(false);
          }}
        />
      )}
      {isLogin && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-96">
            {switchButton ? (
              <LoginUp
                setIsLogin={setIsLogin}
                setSwitchButton={setSwitchButton}
              />
            ) : (
              <SignUp
                setIsLogin={setIsLogin}
                setSwitchButton={setSwitchButton}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Other;
