import React, { useState, useRef, useEffect } from "react"; // Make sure to import your CustomizationOptions component
import { ColorRing } from "react-loader-spinner";
import { setReset } from "../store/downloadButtonRefernecSliceReducer";
import convertBitsToMb from "../convertbits/convertBitsToMb";
import convertBitsToGB from "../convertbits/convertbitstoGb";
import convertBitsToTB from "../convertbits/convertBitsToTB";
import { useDispatch} from "react-redux";
import CustomAlert from "../CustomAlertForSubmision";
import { useSelector } from "react-redux";

import {
  setPlanName,
  setPrice,
} from "../store/chooseBestOffers/BestOffersSlicer";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";
import { useNavigate } from "react-router-dom";


const QrCodeGenerationForm = () => {
  const [loading, setLoading] = useState(false);
  const [buttonColor, setButtonColor] = useState("#4C4E3C");
  const [isHovered, setIsHovered] = useState(false);

  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    purpose: "",
    dataType: "",
    
  });
  const [showPassword, setShowPassword] = useState(false);
  const [filePaths, setFilePath] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [buttonText, setButtonText] = useState("Submit");

  const [UrlName, setUrlName] = useState("");
  const [UrlText, setUrlText] = useState("");
  const [isPopupShown, setIsPopupShown] = useState(false); // Flag to control the popup display
  const dispatch = useDispatch();
  const [fileTitle, setFileTitle] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [QrCodePassword, setQrCodePassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState("");

  useEffect(() => {
    const storedFiles = JSON.parse(localStorage.getItem("files") || "[]");
    const storedFilePaths = JSON.parse(localStorage.getItem("filePaths") || "[]");
    const storedPurpose = JSON.parse(localStorage.getItem("purpose") || "\"\"");
    const storedDataType = JSON.parse(localStorage.getItem("dataType") || "\"\"");
    const storedFileTitle = JSON.parse(localStorage.getItem("fileTitle") || "\"\"");
    const storedFileDescription = JSON.parse(localStorage.getItem("fileDescription") || "\"\"");
    const storedUrlName = JSON.parse(localStorage.getItem("UrlName") || "\"\"");
    const storedUrlText = JSON.parse(localStorage.getItem("UrlText") || "\"\"");
    const storedQrCodePassword = JSON.parse(localStorage.getItem("QrCodePassword") || "\"\"");
    

    if (storedFiles.length > 0) setFiles(storedFiles);
    if (storedFilePaths.length > 0) setFilePath(storedFilePaths);
    if (storedPurpose) setFormData(prev => ({ ...prev, purpose: storedPurpose }));
    if (storedDataType) setServiceName(storedDataType);
    if (storedFileTitle) setFileTitle(storedFileTitle);
    if (storedFileDescription) setFileDescription(storedFileDescription);
    if (storedUrlName) setUrlName(storedUrlName);
    if (storedUrlText) setUrlText(storedUrlText);
    if (storedQrCodePassword) setQrCodePassword(storedQrCodePassword);
  }, []);

  // Update localStorage when files or filePaths changes
  useEffect(() => {
    if (files.length > 0) {

      localStorage.setItem("files", JSON.stringify(files.map(file => ({ name: file.name }))));
    } else {
      localStorage.removeItem("files");
    }
  }, [files]);


  useEffect(() => {
    if (filePaths.length > 0) {
      // Store only the current filePaths array, overwriting any previous value
      localStorage.removeItem("filePaths")
      localStorage.setItem("filePaths", JSON.stringify(filePaths));
    }
    
  }, [filePaths]);
  

  useEffect(() => {
    // Retrieve stored filePaths from local storage
    const storedPath = JSON.parse(localStorage.getItem('filePaths')) || []; // Use an empty array if not found
    // Only set the state if storedPath is an array
    if (Array.isArray(storedPath)) {
      setFilePath(storedPath);
    }

  }, []); // This effect runs only on component mount




  // Update localStorage for other individual fields
  useEffect(() => {
    if (fileTitle) localStorage.setItem("fileTitle", JSON.stringify(fileTitle));
    if (fileDescription) localStorage.setItem("fileDescription", JSON.stringify(fileDescription));
    if (QrCodePassword) localStorage.setItem("QrCodePassword", JSON.stringify(QrCodePassword));
    if (UrlName) localStorage.setItem("UrlName", JSON.stringify(UrlName));
    if (UrlText) localStorage.setItem("UrlText", JSON.stringify(UrlText));
    if (formData.purpose) localStorage.setItem("purpose", JSON.stringify(formData.purpose));
    if (serviceName) localStorage.setItem("dataType", JSON.stringify(serviceName));
  }, [
    fileTitle,
    fileDescription,
    QrCodePassword,
    UrlName,
    UrlText,
    formData.purpose,
    serviceName
  ]);

  const backendUrl = process.env.REACT_APP_ENVIRONMENT ==='production'? 'https://backend.zmqrcode.com' : 'http://localhost:3000';  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };




  const handleInputChangeForServicesName = (event) => {

    if(event.target.value==="Video"){
    setShowAlert(true);
    setAlertMessage("Only Select Mp4 Type");
    }
    if(event.target.value==="Document"){
      setShowAlert(true);
      setAlertMessage("Only Select Pdf Type");
     
      }
     setServiceName(event.target.value);
    setFileDescription("");
    setFileTitle("");
    setFilePath([]);
    setFiles([]);
    setQrCodePassword("");
    localStorage.removeItem("fileTitle");
    localStorage.removeItem("fileDescription");
    localStorage.removeItem("QrCodePassword");
    localStorage.removeItem("files");
    localStorage.removeItem("filePaths");
    localStorage.removeItem("UrlName");
    localStorage.removeItem("UrlText");
    setIsPopupShown(false);
    fileInputRef.current = null;
  
  };

  const profile = JSON.parse(localStorage.getItem("registeredUserData"));


  useEffect(() => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
  
    if ((totalSize>0)&&(totalSize <= 52428800)) {
      const plan = convertBitsToMb(totalSize,1);
      console.log(plan.name+"called");
      console.log(plan.price+"called");
      localStorage.setItem('plan',JSON.stringify(plan.name));
      localStorage.setItem('priceForQr', JSON.stringify(plan.price));
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    } else if ((totalSize > 52428800)&&(totalSize <= 96262144000)) {
      const plan = convertBitsToGB(totalSize);
      localStorage.setItem('plan',JSON.stringify(plan.name));
      localStorage.setItem('priceForQr', JSON.stringify(plan.price));
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    } else if ((totalSize > 96262144000)&&(totalSize > 96262144000)) {
      const plan = convertBitsToTB(totalSize);
      localStorage.setItem('plan',JSON.stringify(plan.name));
      localStorage.setItem('priceForQr', JSON.stringify(plan.price));
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    }
  }, [files, dispatch]);

  

  let oversizedFiles = []; // Declare outside to persist across function calls
  




  const chooseFileHandleChange = async (event) => {
   
    setButtonColor("#517b7b");
    setButtonText("Submit");
    setLoading(true);
   
  
    const selectedFiles = Array.from(event.target.files);
    const validFiles = []; // Temp array for new valid files
    oversizedFiles = []; // Reset the oversizedFiles array for each new selection
  
    // Filter valid files and collect oversized file names
    selectedFiles.forEach((file) => {
   
        validFiles.push(file); // Add valid new files

    });
  
    // Show alert if there are any oversized files
  
    // If no valid files, stop further execution
    if (validFiles.length === 0) {
      setLoading(false); // Stop the loading state if there are no valid files
      return;
    }
  
    // Add the newly selected valid files to the existing files in state
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles, ...validFiles];
      // Return unique files based on name
      return Array.from(new Set(newFiles.map(file => file.name))).map(name => newFiles.find(file => file.name === name));
    });
  
  
    const formData = new FormData();
    // Append all the files (previously uploaded + newly selected)
    [...files, ...validFiles].forEach((file) => {
      formData.append("file", file);
    });
  
    try {
      const uploadData = await postUploadFile(
        `${backendUrl}/upload`,
        formData
      );

      const data = uploadData.data;

      if (Array.isArray(data)) {
        const newFilePaths = data.map((item) => item.url);

        setFilePath((prevFilePaths) => {
          const combinedPaths = [...prevFilePaths, ...newFilePaths];
          return Array.from(new Set(combinedPaths)); // Remove duplicates
        });
      } else {
        alert("Data is not an array", data);
      }

      dispatch(setReset());
    } catch (error) {
      localStorage.removeItem("files");
      localStorage.removeItem("filePaths");
      setFiles([]);
      setFilePath([]);
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
    }
  };
  


  const removeFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);
      
      // Remove corresponding file path
      setFilePath((prevFilePaths) =>
        prevFilePaths.filter((_, i) => i !== index)
      );
  
      // Update localStorage after the files have been updated
      if (updatedFiles.length > 0) {
        const resultFile = updatedFiles.map((file) => ({ name: file.name }));
        localStorage.setItem("files", JSON.stringify(resultFile));
      }
  
      return updatedFiles;
    });
  
    // Clear file input if it was the last file
    if (files.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  

  const postUploadFile = async (url, formData) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error during upload:", error);
         setShowAlert(true);
      setAlertMessage("Error uploading files:", error);
      throw error;
    }
  };



  useEffect(()=>{
    const storedItem = JSON.parse(localStorage.getItem("ServiceId"));
    if(storedItem){
      dispatch(setInstanceId(storedItem))
    }
  },[])

  const navigate = useNavigate();

  const handleSubmitService = async () => {
    if(profile==="" || profile===null){
      setShowAlert(true);
      setAlertMessage("Your already registered please Visit:'https://zmqrcode.com/'")
      localStorage.removeItem("files");
      localStorage.removeItem("filePaths");
      setFiles([]);
      setFilePath([]);
    }

    if(filePaths.length===0){
      setShowAlert(true);
      setAlertMessage("Please Select Files Again");

    }
  
    const formData = {
      serviceName: serviceName,
      fileTitle: fileTitle,
      fileDescription: fileDescription,
      filePaths: filePaths,
      QrCodePassword: QrCodePassword || "",
      userId: profile.userid,
    };
    if(filePaths.length===0){
      setShowAlert(true);
    }
    try {
      if (filePaths.length >= 1) {
        setButtonText("Submitted");
        console.log(filePaths);
        const response = await axios.post(
          `${backendUrl}/purchaseQrCodeUser`,
          formData
        );
        const purchasedServiceId = response.data.url;
        if (purchasedServiceId) {
          localStorage.setItem("ServiceId",JSON.stringify(purchasedServiceId))
          dispatch(setInstanceId(purchasedServiceId));
        }
        localStorage.removeItem('purpose');
        localStorage.removeItem('dataType');
        localStorage.removeItem("fileTitle");
        localStorage.removeItem("fileDescription");
        localStorage.removeItem("QrCodePassword");
        localStorage.removeItem("files");
        localStorage.removeItem("filePaths");
        localStorage.removeItem("UrlName");
        localStorage.removeItem("UrlText");
        setFileTitle("");
        setFileDescription("");
        setQrCodePassword("");
        setFiles([]);
        setFilePath([]);
        setUrlName("");
        setUrlText("");
        navigate('/QrCustomization');      
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset input field
      }

      setIsPopupShown(false);

    } catch (error) {
      console.error("Error creating service instance:", error);
    }
  };






  const handleInputBoxClick = (event) => {
    if (!isPopupShown) { // Only show the popup if it hasn't been shown yet
      event.preventDefault(); // Prevents the file dialog from opening immediately
      setIsPopupShown(true); // Set the flag to true so popup only shows once

      // Show the message pop-up
      alert("MAX upload file Limits is 5 GB, Please don't reload while uploading");

      // Open the file dialog after the alert is closed
      setTimeout(() => {
        fileInputRef.current.click(); // Programmatically open the file dialog
      }, 0);
    }
  };


  // Form submission handler
  const handleSubmitForUrl = async () => {
    console.log(filePaths);
    if (profile.userid !== "") {
      const formData = {
        serviceName: serviceName,
        UrlName: UrlName,
        UrlText: UrlText,
        QrCodePassword: QrCodePassword,
        userId: profile.userid,
      };

      try {
        const response = await axios.post(
          `${backendUrl}/purchaseQrCodeUserForUrl`,
          formData
        );
        const purchasedServiceUrl = response.data.url;

        dispatch(setInstanceId(purchasedServiceUrl));
        setButtonText("Submitted");
        dispatch(setPrice(99));
        dispatch(setPlanName("Basic Starter plan"));
        localStorage.removeItem("fileTitle");
        localStorage.removeItem("fileDescription");
        localStorage.removeItem("QrCodePassword");
        localStorage.removeItem("files");
        localStorage.removeItem("filePaths");
        localStorage.removeItem("UrlName");
        localStorage.removeItem("UrlText");
        setFileTitle("");
        setFileDescription("");
        setQrCodePassword("");
        setFiles([]);
        setFilePath([]);
        setUrlName("");
        setUrlText("");
        setButtonText("Submitted");
        navigate('/QrCustomization');  
      } catch (error) {
        console.error(error);
      }
    }
  };

  

  // Effect to load planName and price from localStorage on component mount
  const BestPlans = useSelector((state)=>(state.Bestplans))

  useEffect(()=>{
    if((BestPlans.price!==null)&&(BestPlans.price!==0)){
    setAlertMessage(`Your current Plan ${BestPlans.planName} and price:${BestPlans.price}`);
    setShowAlert(true);
    }
  },[BestPlans])



  return (
    <div className="max-w-md mx-auto p-6 rounded-md shadow-md bg-customBlue font-raleway">
      <form
        onSubmit={(e) => {
          e.preventDefault(); /* Handle form submission */
        }}
      >
        <div className="mb-4">
          <label className="block text-gray-700">Purpose</label>
          <select
            name="purpose"
            value={formData.purpose}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border rounded-md"
          >
            <option value="">Select Purpose</option>
            <option value="Personal">Personal</option>
            <option value="Business">Business</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Choose Data Type</label>
          <select
            name="dataType"
            value={serviceName}
            onChange={handleInputChangeForServicesName}
            className="w-full mt-1 p-2 border rounded-md"
          >
            <option value="">Search Data Type</option>
            <option value="Audio">Audio</option>
            <option value="Video">Video</option>
            <option value="Image">Image</option>
            <option value="Document">Document</option>
            <option value="URL">Social Media Links</option>
            <option value="Others">Others</option>
          </select>
        </div>

        {serviceName === "Audio" && (
          <div className="mb-4">
            <h2 className="text-xl">Audio Form</h2>
            <label className="block text-gray-700">Audio Title</label>
            <input
              type="text"
              name="audioTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Audio Files
              </label>
              <input
                id="files"
                name="files"
                type="file"
                accept=".mp3, .wav, .ogg"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                onFocus={handleInputBoxClick}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {!loading && (
                    <button
                      type="button" // Ensures this button does not act as a submit button
                      onClick={() => removeFile(index)} // Calls removeFile function with the file index
                      className="text-red-500 hover:text-red-700"
                      aria-label={`Remove file at index ${index}`} // Accessibility improvement
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              { (!loading)&& (
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText}
                </button>
              )}
            </div>
          </div>
        )}
        {serviceName === "Video" && (
          <div className="mb-4">
            <h2 className="text-xl">Video Form</h2>
            <label className="block text-gray-700">Video Title</label>
            <input
              type="text"
              name="VideoTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Video Files
              </label>

              <input
                id="files"
                name="files"
                type="file"
                accept=".mp4, .webm, .ogg"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                onFocus={handleInputBoxClick}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {!loading && (
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              {(!loading)&&(
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText}
                </button>
              )}
            </div>
          </div>
        )}

        {serviceName === "Image" && (
          <div className="mb-4">
            <h2 className="text-xl">Image Form</h2>
            <label className="block text-gray-700">Image Title</label>
            <input
              type="text"
              name="ImageTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Image Files
              </label>
              <input
                id="files"
                name="files"
                type="file"
                accept="image/png, image/jpeg , image/jpg"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                onFocus={handleInputBoxClick}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {(!loading && (oversizedFiles.length===0))&& (
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              {(!loading && (oversizedFiles.length===0))&& (
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText}
                </button>
              )}
            </div>
          </div>
        )}

        {serviceName === "Document" && (
          <div className="mb-4">
            <h2 className="text-xl">Document Form</h2>
            <label className="block text-gray-700">Document Title</label>
            <input
              type="text"
              name="DocumentTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Document Files
              </label>
              <input
                id="files"
                name="files"
                type="file"
                accept=".pdf"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                onFocus={handleInputBoxClick}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {!loading && (
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              {(!loading && (oversizedFiles.length===0))&&(
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText}
                </button>
              )}
            </div>
          </div>
        )}
        {serviceName === "Others" && (
          <div className="mb-4">
            <h2 className="text-xl">Others Form</h2>
            <label className="block text-gray-700">Others Title</label>
            <input
              type="text"
              name="othersTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Other Files
              </label>
              <input
                id="files"
                name="files"
                type="file"
                accept="image/png, image/jpeg , image/jpg"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                onFocus={handleInputBoxClick}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                    "#fff",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {!loading && (
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              {(!loading && (oversizedFiles.length===0))&& (
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText !== "" ? buttonText : "Submit"}
                </button>
              )}
            </div>
          </div>
        )}

        {serviceName === "URL" && (
          <div className="mb-4">
            <h2 className="text-xl">Links Form</h2>
            <div className="mb-4">
              <label className="block text-gray-700">WebSite Title</label>
              <input
                type="text"
                name="Urltitle"
                value={UrlName}
                onChange={(e) => setUrlName(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
            </div>
            <label className="block text-gray-700">URL</label>
            <input
              type="text"
              name="url"
              value={UrlText}
              onChange={(e) => {
                setUrlText(e.target.value);
                setButtonText("Submit");
              }}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <div className="mb-4 relative">
                <label className="block text-gray-700">Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={QrCodePassword}
                  onChange={(e) => setQrCodePassword(e.target.value)}
                  placeholder="NA"
                  className="w-full mt-1 p-2 border rounded-md"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
              <div className="flex justify-between items-center p-4">
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitForUrl}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
        )}
      </form>

      {showAlert && (
        <CustomAlert
          message={alertMessage}
          onClose={() => {
            setShowAlert(false);
          }}
        />
      )}


    </div>
  );
};

export default QrCodeGenerationForm;
