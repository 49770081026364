import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';

const PdfForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [files, setFiles] = useState([]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const removeFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <div className="max-w-lg mx-auto">
            <h1 className="text-2xl font-bold text-center mb-6">Document Upload</h1>
            <Formik
                initialValues={{
                    documentName: '',
                    documentType: '',
                    files: [], // Initialize as an empty array
                    password: ''
                }}
                onSubmit={(values) => {
                    // Handle form submission
                    console.log(values, files);
                }}
            >
                {({ setFieldValue, isSubmitting }) => (
                    <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        {/* Document Name input */}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="documentName">
                                Document Name
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="documentName"
                                placeholder="NA"
                            />
                        </div>

                        {/* Document Type input */}
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="documentType">
                                Document Type Be Pdf
                            </label>
                            <Field
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                name="documentType"
                                placeholder="NA"
                            />
                        </div>

                        {/* File input with validation */}
                        <div className="mb-6">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="file">
                                Choose Document File(s)
                            </label>
                            <input
                                id="file"
                                name="file"
                                type="file"
                                accept="application/pdf"
                                multiple
                                onChange={(event) => {
                                    handleFileChange(event);
                                    setFieldValue("files", [...files, ...event.target.files]);
                                }}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <div className="mt-2">
                                {files.map((file, index) => (
                                    <div key={index} className="flex items-center justify-between mt-2">
                                        <span className="text-gray-700">{file.name}</span>
                                        <button
                                            type="button"
                                            onClick={() => removeFile(index)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="text-customBlue" />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Password input with visibility toggle */}
                        <div className="mb-4 relative">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                Password
                            </label>
                            <div className="">
                                <Field
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="NA"
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute 
                                    md:right-[15px]
                                    top-1/2
                                    right-[-70px]
                                    text-gray-600 hover:text-gray-900">
                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="1x" color="#1D91AA" />
                                </button>
                            </div>
                        </div>

                        {/* Submit button */}
                        <div className="flex items-center justify-between">
                            <button
                                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                                style={{ backgroundColor: '#1D91AA' }}
                                disabled={isSubmitting}
                            >
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PdfForm;
