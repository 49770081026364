import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import QRCodeStyling from "qr-code-styling";
import { setImage } from "../store/QrCodeCustmizable/QrCodecustmizableSlicer";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";
import axios from "axios";
import {
  setData,
  setReset,
} from "../store/QrCodeCustmizable/QrCodecustmizableSlicer";

import {
  setDotsColor,
  setCornersDotColor,
  setCornersSquareColor,
} from "../store/QrCodeCustmizable/QrCodecustmizableSlicer";
import useLoadScript from "../reusableHook/useLoadScript";
import { setDownloadText } from "../store/downloadButtonRefernecSliceReducer";
import CustomAlert from "../CustomAlertForSubmision";
import { useNavigate } from "react-router-dom";
import AlertMessageForTermsAndCondition from "./AlertMessageForTermsAndCondiotion";

const CustomizationOptions = ({ serviceName }) => {
  const [filetype, setFiletype] = useState("png");
  const [accepted, setAccepted] = useState(false);
  // State for selected color
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showTermsAlert, setShowTermsAlert] = useState(false);

  const qrCodeRef = useRef(null);
  const qrCodeInstance = useRef(null);
  const qrCodeState = useSelector((state) => state.QrCodeCustmizable);
  const [color, setColor] = useState("#000000"); // State for selected color
  const colorInputRef = useRef(null);

  const dispatch = useDispatch();
  const { loaded, error } = useLoadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );

  const [urlOfQr, setUrl] = useState("");

  const downloadText = useSelector((state) => state.downloadText.downloadText);

  const profile = JSON.parse(localStorage.getItem("registeredUserData"));

  const BestPlans = {
    price: JSON.parse(localStorage.getItem("priceForQr")),
    planName: JSON.parse(localStorage.getItem("plan")),
  };

  const serviceUrl = JSON.parse(localStorage.getItem("ServiceId"));

  const navigate = useNavigate();

  const onExtensionChange = (e) => {
    setFiletype(e.target.value); // Set file type when changed
  };

  const backendUrl =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? "https://backend.zmqrcode.com"
      : "http://localhost:3000";

  const handlePayment = async () => {
    if (profile === null) {
      setShowAlert(true);
      setAlertMessage(
        "Please visit the ZM QR Code Website: https://zmqrcode.com/ to register."
      );
      return;
    }

    if (accepted === false) {
      setShowAlert(true);
      setAlertMessage("Please accept Terms & Conditions.");
      return;
    }

    if (!loaded) {
      alert("Razorpay SDK is still loading...");
      return;
    }

    if (error) {
      alert("Failed to load Razorpay SDK. Please try again.");
      return;
    }

    try {
      const { data } = await axios.post(`${backendUrl}/orders`, {
        amount: BestPlans.price === 0 ? 1 * 100 : BestPlans.price * 100,
        currency: "INR",
      });

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: data.amount,
        currency: data.currency,
        order_id: data.order_id,
        name: `ZM QR Code Service You choose: ${BestPlans.planName}`,
        description: "Test Transaction for QR Code",
        handler: async function (response) {
          alert(
            "Payment Successful! Payment ID: " + response.razorpay_payment_id
          );
          try {
            const formData = {
              username: profile.username,
              email: profile.email,
              planName: BestPlans.planName,
              price: BestPlans.price,
              transationId: response.razorpay_payment_id,
            };

            // POST request with formData to record the payment
            const paymentRemainder = await axios.post(
              `${backendUrl}/paymentDone`,
              formData
            );
            if (paymentRemainder.status === 201) {
              setShowAlert(true);
              setAlertMessage(
                `Thank you for choosing our Plan ${BestPlans.planName}, price: ${BestPlans.price}`
              );
            }
          } catch (error) {
            setShowAlert(true);
            setAlertMessage(
              "An error occurred while saving the payment details."
            );
          }
          dispatch(setData(serviceUrl));
          navigate("/ThankyouMessage");
        },
        prefill: {
          name: profile.username,
          email: profile.email,
        },
        theme: {
          color: "#F37254",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      // Show the specific error message from the backend
      alert(
        `Error while creating order: ${
          error.response?.data?.message || "Please try again."
        }`
      );
    }
  };

  useEffect(() => {
    if (qrCodeState.data && qrCodeState.data !== "https://zmqrcode.com/") {
      download();
    }
  }, [qrCodeState.data]);

  useEffect(() => {
    const serviceId = localStorage.getItem("serviceInstanceId"); // Get the item as a string

    // Check if serviceId exists and is a valid JSON
    if (serviceId) {
      try {
        const parsedServiceId = JSON.parse(serviceId); // Parse it if it exists
        dispatch(setInstanceId(parsedServiceId)); // Dispatch action with the parsed ID
      } catch (error) {
        console.error("Error parsing serviceInstanceId:", error);
        // Handle parsing error if needed
      }
    }
  }, [dispatch]);

  const download = async () => {
    if (qrCodeRef.current) {
      const qrCodeToDownload = new QRCodeStyling({
        width: qrCodeState.width,
        height: qrCodeState.height,
        image: qrCodeState.image,
        margin: qrCodeState.margin,
        data: qrCodeState.data,
        dotsOptions: qrCodeState.dotsOptions,
        cornersDotOptions: qrCodeState.cornersDotOptions,
        cornersSquareOptions: qrCodeState.cornersSquareOptions,
        backgroundOptions: qrCodeState.backgroundOptions,
        imageOptions: qrCodeState.imageOptions,
        errorCorrectionLevel: "H",
      });

      // Append QR code to a temporary DOM element to render it
      const tempDiv = document.createElement("div");
      qrCodeToDownload.append(tempDiv);

      try {
        // Wait for the QR code to render before attempting download
        qrCodeToDownload.download({ extension: filetype });

        // Generate raw data (blob) for the image
        const blob = await qrCodeToDownload.getRawData("png");

        if (blob) {
          const qrFile = new File([blob], "qrcode.png", { type: "image/png" });

          // Prepare the file for upload using FormData
          const formData = new FormData();
          formData.append("file", qrFile); // Add the file to FormData

          const id = serviceUrl.split("id=")[1];
          // Post the file to the server

          const responseData = await axios.post(
            serviceName === "Url"
              ? `${backendUrl}/saveqrcodeimageForUrl?id=${id}`
              : `${backendUrl}/saveqrcodeimage?id=${id}`, // URL resolved correctly
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Required for sending files
              },
            }
          );
          localStorage.removeItem("");
          localStorage.removeItem("registeredUserData");
          localStorage.removeItem("priceForQr");
          localStorage.removeItem("plan");
          localStorage.removeItem("logoUrl");
          localStorage.removeItem("QrColor");
          localStorage.removeItem("ServiceId");
          dispatch(setDownloadText("Downloaded"));
        } else {
          console.error("Error: Blob data could not be generated.");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        // Clean up the temporary element
        tempDiv.remove();
        dispatch(setReset());
        // Dispatch reset action if needed
      }
    }
  };

  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };

  useEffect(() => {
    if (!qrCodeRef.current) return;

    qrCodeInstance.current = new QRCodeStyling({
      width: qrCodeInstance.width,
      height: qrCodeInstance.height,
      margin: qrCodeState.margin,
      image: qrCodeState.image, // Use state image
      data: qrCodeState.data,
      dotsOptions: {
        color: qrCodeState.dotsOptions.color,
        type: qrCodeState.dotsOptions.type,
      },
      cornersDotOptions: {
        color: qrCodeState.cornersDotOptions.color,
        type: qrCodeState.cornersDotOptions.type,
      },
      cornersSquareOptions: {
        color: qrCodeState.cornersSquareOptions.color,
        type: qrCodeState.cornersSquareOptions.type,
      },
      backgroundOptions: {
        color: qrCodeState.backgroundOptions.color,
      },
      imageOptions: {
        crossOrigin: qrCodeState.imageOptions.crossOrigin,
        margin: qrCodeState.imageOptions.margin,
        imageSize: qrCodeState.imageOptions.imageSize,
        hideBackgroundDots: qrCodeState.imageOptions.hideBackgroundDots,
      },
      errorCorrectionLevel: "H",
    });

    qrCodeInstance.current.append(qrCodeRef.current);

    return () => {
      qrCodeInstance.current = null;
    };
  }, [qrCodeState]);

  useEffect(() => {
    if (qrCodeInstance.current) {
      qrCodeInstance.current.update({
        width: qrCodeState.width,
        height: qrCodeState.height,
        image: qrCodeState.image,
        margin: qrCodeState.margin,
        data: qrCodeState.data,
        dotsOptions: {
          color: qrCodeState.dotsOptions.color,
          type: qrCodeState.dotsOptions.type,
        },
        // other QR code options...
      });
    } else if (qrCodeRef.current) {
      qrCodeInstance.current = new QRCodeStyling({
        width: qrCodeState.width,
        height: qrCodeState.height,
        image: qrCodeState.image,
        // other initialization code...
      });
      qrCodeInstance.current.append(qrCodeRef.current);
    }
  }, [qrCodeState]);

  useEffect(() => {
    const storedQrLogo = JSON.parse(localStorage.getItem("logoUrl"));

    dispatch(setImage(storedQrLogo)); // Parse and dispatch the stored value
  }, [dispatch]); // Added dispatch to the dependency array for clarity]

  useEffect(() => {
    dispatch(setDotsColor(color));
    dispatch(setCornersDotColor(color));
    dispatch(setCornersSquareColor(color));
  }, [color, dispatch]); // Include `dispatch` in the dependency array

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setColor(newColor);

    dispatch(setDotsColor(newColor));
    dispatch(setCornersDotColor(newColor));
    dispatch(setCornersSquareColor(newColor));
    // Update color if 'single' type is selected
  };

  const handleIconClick = () => {
    if (colorInputRef.current) {
      colorInputRef.current.click();
    }
  };

  useEffect(() => {
    if (color !== "") {
      localStorage.setItem("QrColor", JSON.stringify(color));
    }
  }, [color]);

  useEffect(() => {
    setColor(JSON.parse(localStorage.getItem("QrColor")));
  }, []);

  const handleLogoUpdate = async (event) => {
    try {
      if (!event.target.files[0]) {
        console.error("No file selected");
        return;
      }

      const formData = new FormData();
      formData.append("file", event.target.files[0]); // Ensure the key matches server-side

      const data = await postUploadImage(
        `${backendUrl}/uploadFileForUser`,
        formData
      );

      const urlOfImage = `${backendUrl}/getUploadForUserByFileId/${data.uploadedFiles[0].fileId}`;
      console.log(urlOfImage + "called");
      if (data !== "") {
        localStorage.setItem("logoUrl", JSON.stringify(urlOfImage));
      }

      setUrl(urlOfImage);
      dispatch(setImage(urlOfImage));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const postUploadImage = async (url, formData) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type for form data
        },
      });

      return response.data; // Return the data directly from the response
    } catch (error) {
      console.error("Error during upload:", error);
      throw error; // Rethrow error to handle in the caller
    }
  };

  return (
    <div className="bg-customBlue">
      <div className="bg-white max-w-sm mx-auto border rounded-lg shadow-md p-4 space-y-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Customization </h2>
          <img
            src="images/ZMlogoforclienregistraionForm.png"
            alt="Logo"
            className="h-10 w-10"
          />
        </div>

        <div className="text-center  flex flex-col justify-center items-center m-2 w-full">
          <h2 className="text-lg font-semibold">QR Code</h2>

          <div ref={qrCodeRef} className="bg-gray-200"></div>
        </div>

        {/* File Type Selection and Download Button */}
        <div className="space-y-2">
          <h2 className="text-lg font-semibold">Download Options</h2>
          <label>
            <select
              onChange={onExtensionChange}
              value={filetype}
              className="w-auto rounded-lg border border-gray-400 border-opacity-50 focus:outline-none"
            >
              <option value="png">PNG</option>
              <option value="jpeg">JPEG</option>
              <option value="webp">WEBP</option>
            </select>
          </label>
          <button
            className="w-full p-2 font-raleway text-white rounded-md my-4 text-sm bg-customBlue"
            onClick={handlePayment}
          >
            {downloadText !== "" ? downloadText : "download"}
          </button>
        </div>
        {/* Other Customization Options */}
        <div>
          <div className="flex justify-center items-center">
            {/* Choose Color Button */}
            <div className="p-2 flex justify-start relative ">
              {/* <FontAwesomeIcon
                      icon={faFillDrip}
                      size="1x"
                      className="cursor-pointer text-[#122323] bg-transparent shadow-none mt-1 text-[45px]"
                      onClick={handleIconClick}
                  /> */}
              <div
                className="w-[120px] h-[40px] rounded-md text-center cursor-pointer flex items-center justify-center bg-customBlue"
                style={{
                  color: "#fff",
                  padding: "5px",
                }}
                onClick={() => colorInputRef.current.click()} // Trigger color input click
              >
                Choose Color
              </div>
              <input
                type="color"
                ref={colorInputRef}
                onChange={handleColorChange}
                value='1D91AA'
                style={{ display: "none" }} // Completely hide the input
              />
            </div>

            <label className="w-[178px] p-[5px] bg-customBlue text-white  rounded-md text-center cursor-pointer">
              Add your own logo
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpdate}
                className="hidden" // Hide the file input but keep it functional
              />
            </label>
          </div>
          <div className="flex flex-col items-center mb-6">
            <div className="flex flex-col">
              <div>
                <div className="flex justify-center items-center">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={accepted}
                    onChange={handleCheckboxChange}
                    className="w-5 h-5 mr-2"
                  />
                  <label htmlFor="terms" className="text-gray-700">
                    I accept the terms and conditions
                  </label>
                </div>
                <div>
                  <button
                    className="bg-customBlue hover:bg-white hover:text:customBlue p-[10px] rounded-md"
                    onClick={() => {
                      setShowTermsAlert(true);
                    }}
                  >
                    view
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img
                  src="images/ZMlogoforclienregistraionForm.png"
                  alt="Logo"
                  className="h-10 w-10 mt-10"
                />
                <label className="text-black-500 mt-10 sm:text-xl py-4 px-6">
                  © 2024. All Rights Reserved. ZM QR Code Services
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          onClose={() => {
            setShowAlert(false);
            // Redirect to external site
          }}
        />
      )}

      {showTermsAlert && (
        <AlertMessageForTermsAndCondition
          onClose={() => {
            setShowTermsAlert(false);
          }}
        />
      )}
    </div>
  );
};

export default CustomizationOptions;
