const plansForMb = [
  {
    storageRange: "10 MB",
    price: 99,
    name: "Basic Starter",
    description:
      "Ideal for minimal storage needs. Perfect for small personal projects.",
    reviews: {
      count: 100000,
      rating: 3.5,
    },
  },
  {
    storageRange: "30 MB",
    price: 199,
    name: "Entry Level",
    description:
      "Great for small businesses or hobbyists needing a bit more space.",
    reviews: {
      count: 200000,
      rating: 4.0,
    },
  },
  {
    storageRange: "70 MB",
    price: 299,
    name: "Essential Plan",
    description:
      "A solid choice for growing needs with moderate storage requirements.",
    reviews: {
      count: 150000,
      rating: 4.2,
    },
  },
];

const convertBitsToMb = (sizeInByts, usageCountOfuser) => {
  const sizeOffile = sizeInByts / (1024 * 1024); // File size in MB
  
  console.log(sizeOffile);

  for (let plan of plansForMb) {
    const [storageSize] = plan.storageRange.split(" "); // Extract the storage number in MB

    // Check if the first use and size is under 5MB, return free plan
    if (sizeOffile <= 5 && usageCountOfuser === 1) {
      return {price:0 , name:"first sign Up"}; // Free on first use if under 5MB
    }

    // Check if the file size fits within the current plan's range
    if (sizeOffile <= parseFloat(storageSize)) {
      return { price: plan.price, name: plan.name };
      // Return the price for the plan that fits
    }
  }

  // If no plan fits, return null or an appropriate value
  return "No suitable plan";
};

export default convertBitsToMb;
